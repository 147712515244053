<template>
      <basic-container>
        <avue-crud
          :option="option"
          :search.sync="search"
          :table-loading="loading"
          :data="data"
          ref="crud"
          v-model="form"
          :permission="permissionList"
          @row-del="rowDel"
          @row-update="rowUpdate"
          @row-save="rowSave"
          :before-open="beforeOpen"
          :page.sync="page"
          @search-change="searchChange"
          @search-reset="searchReset"
          @selection-change="selectionChange"
          @current-change="currentChange"
          @size-change="sizeChange"
          @refresh-change="refreshChange"
          @on-load="onLoad"
        >
          <template slot="menuLeft">
            <el-button
              type="success"
              size="small"
              plain
              v-if="userInfo.role_name.includes('admin')"
              icon="el-icon-upload2"
              @click="handleImport"
              >导入
            </el-button>
            <el-button
              type="warning"
              size="small"
              plain
              v-if="userInfo.role_name.includes('admin')"
              icon="el-icon-download"
              @click="handleExport"
              >导出
            </el-button>
          </template>
          <template slot-scope="{ row }" slot="tenantName">
            <el-tag>{{ row.tenantName }}</el-tag>
          </template>
          <template slot-scope="{ row }" slot="roleName">
            <el-tag>{{ row.roleName }}</el-tag>
          </template>
          <template slot-scope="{ row }" slot="deptName">
            <el-tag>{{ row.deptName }}</el-tag>
          </template>
          <template slot-scope="{ row }" slot="userTypeName">
            <el-tag>{{ row.userTypeName }}</el-tag>
          </template>
          <template slot-scope="{ row }" slot="lastLoginTime">
            {{ row.lastLoginTime ? row.lastLoginTime : "暂未登录" }}
          </template>
           <template slot-scope="{ row, index }" slot="state">
             <avue-switch v-model="switchArr[index]" :disabled="!permission.no_fly" :dic="dic" @click="changeStatus(row)"></avue-switch>
          </template>
          <template slot-scope="{ row }" slot="menu">
            <el-button
              type="text"
              size="small"
              icon="el-icon-circle-check"
              v-if="permission.user_status"
              @click="handleFrezee(row)"
            >
              {{ row.state === 1 ? "停用" : "启用" }}
            </el-button>
            <!-- <el-button
              type="text"
              icon="el-icon-view"
              size="small"
              v-if="permission.user_status"
              @click="handleView(row)"
              >日志
            </el-button> -->
          </template>
        </avue-crud>
        <el-dialog
          title="用户角色配置"
          append-to-body
          :visible.sync="roleBox"
          width="345px"
        >
          <el-tree
            :data="roleGrantList"
            show-checkbox
            check-strictly
            default-expand-all
            node-key="id"
            ref="treeRole"
            :default-checked-keys="roleTreeObj"
            :props="props"
          >
          </el-tree>

          <span slot="footer" class="dialog-footer">
            <el-button @click="roleBox = false">取 消</el-button>
            <el-button type="primary" @click="submitRole">确 定</el-button>
          </span>
        </el-dialog>
        <el-dialog
          title="用户数据导入"
          append-to-body
          :visible.sync="excelBox"
          width="555px"
        >
          <avue-form
            :option="excelOption"
            v-model="excelForm"
            :upload-after="uploadAfter"
          >
            <template slot="excelTemplate">
              <el-button type="primary" @click="handleTemplate">
                点击下载<i class="el-icon-download el-icon--right"></i>
              </el-button>
            </template>
          </avue-form>
        </el-dialog>
        <el-dialog
          title="用户平台配置"
          append-to-body
          :visible.sync="platformBox"
        >
          <avue-crud
            :option="platformOption"
            :table-loading="platformLoading"
            :data="platformData"
            ref="platformCrud"
            v-model="platformForm"
            :before-open="platformBeforeOpen"
            :page.sync="platformPage"
            :permission="platformPermissionList"
            @row-update="platformRowUpdate"
            @search-change="platformSearchChange"
            @search-reset="platformSearchReset"
            @selection-change="platformSelectionChange"
            @current-change="platformCurrentChange"
            @size-change="platformSizeChange"
            @refresh-change="platformRefreshChange"
            @on-load="platformOnLoad"
          >
            <template slot-scope="{ row }" slot="tenantName">
              <el-tag>{{ row.tenantName }}</el-tag>
            </template>
            <template slot-scope="{ row }" slot="userTypeName">
              <el-tag>{{ row.userTypeName }}</el-tag>
            </template>
          </avue-crud>
        </el-dialog>
      </basic-container>

</template>

<script>
import {
  getuserList,
  getUser,
  getUserPlatform,
  remove,
  update,
  updatePlatform,
  add,
  grant,
  userFreeze,
  resetPassword,
  changeFlyState
} from "@/api/system/user";
import { getDeptTree, getDeptLazyTree,getDeptByTenantID } from "@/api/system/dept";
import { getRoleTree,getAppRoleListByTenant } from "@/api/system/role";
import { mapGetters } from "vuex";
import website from "@/config/website";
import { getToken } from "@/util/auth";
// import loginRecord from "./components/loginRecord";
import {
  maxLength200,
  maxLength300,
  isvalidatemobile,
  validateEmail,
} from "@/util/validate";
import { exportExcel } from "@/util/export";

export default {
  name: 'user-manager',
  data() {
    var validatePass = (rule, value, callback) => {
      // 是数字
      var isDigit = /^.*[0-9]+.*/;
      // isLowerCase 小写字母
      var isLowerCase = /^.*[a-z]+.*/;
      // isUpperCase 大写字母
      var isUpperCase = /^.*[A-Z]+.*/;

      // 记录匹配的次数
      var num = 0;

      if (isDigit.test(value)) {
        num = num + 1;
      }
      if (isLowerCase.test(value)) {
        num = num + 1;
      }
      if (isUpperCase.test(value)) {
        num = num + 1;
      }

      if (
        value == null ||
        value.length < 6 ||
        value.length > 16 ||
        value.length == ""
      ) {
        callback(new Error("密码位数不对，长度最少6位，最大16位"));
      }
      if (num <= 2) {
        callback(new Error("密码复杂度不足,需同时包含大小写、字母、数字"));
      } else {
        callback();
      }
    };
    // const validatePass = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入密码"));
    //   } else {
    //     callback();
    //   }
    // };
    // const validatePass2 = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请再次输入密码"));
    //   } else if (value !== this.form.password) {
    //     callback(new Error("两次输入密码不一致!"));
    //   } else {
    //     callback();
    //   }
    // };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      if (value) {
        if (isvalidatemobile(value)[0]) {
          callback(new Error(isvalidatemobile(value)[1]));
        } else {
          callback();
        }
      } else {
        callback();
      }
      // if (isvalidatemobile(value)[0]) {
      //   callback(new Error(isvalidatemobile(value)[1]));
      // } else {
      //   callback();
      // }
    };
    const validateEmails = (rule, value, callback) => {
      if (!validateEmail(value)) {
        callback(new Error("邮箱格式不正确"));
      } else {
        callback();
      }
    };

    return {
      switchArr: [],
      dic:[{
        label:'',
        value:0
      },{
        label:'',
        value:1
      }],
      treeDepDestroy: false,
      form: {},
      search: {},
      roleBox: false,
      excelBox: false,
      platformBox: false,
      initFlag: true,
      selectionList: [],
      query: {},
      loading: true,
      platformLoading: false,
      showRecord: false,
      roleList:[],
      deptList:[],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      platformPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      init: {
        roleTree: [],
        deptTree: [],
      },
      props: {
        label: "title",
        value: "key",
      },
      roleGrantList: [],
      roleTreeObj: [],
      treeDeptId: "",
      treeData: [],
      treeOption: {
        nodeKey: "id",
        lazy: true,
        treeLoad: function (node, resolve) {
          const parentId = node.level === 0 ? 0 : node.data.id;
          getDeptLazyTree(parentId).then((res) => {
            resolve(
              res.data.data.map((item) => {
                return {
                  ...item,
                  leaf: !item.hasChildren,
                };
              })
            );
          });
        },
        addBtn: false,
        menu: false,
        size: "small",
        props: {
          labelText: "标题",
          label: "title",
          value: "value",
          children: "children",
        },
      },
      option: {
        height:'auto',
        calcHeight: 30,
        dialogHeight: 600,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        selection: true,
        columnBtn: false,
        viewBtn: false,
        //dialogType: 'drawer',
        searchBtnText: "搜索",
        dialogClickModal: false,
        column: [
          // {
          //   label: "用户ID",
          //   prop: "id",
          //   search: true,
          //   display: false,
          // },
          // {
          //   label: "用户ID",
          //   prop: "id",
          //   search: false,
          //   display: false,
          // },
          {
            label: "账户名",
            prop: "account",
            search: true,
            display: false,
          },
          {
            label: "姓名",
            prop: "realName",
            search: true,
            display: false,
          },
          {
            label: "性别",
            prop: "sexName",
            display: false,
          },
          // {
          //   label: "角色",
          //   prop: "roleId",
          //   multiple: true,
          //   type: "tree",
          //   // dicData: [],
          //   dicUrl: "/api/blade-system/role/tree",
          //   props: {
          //     label: "title",
          //   },

          //   props: {
          //     label: "title",
          //   },
          //   checkStrictly: true,
          //   slot: true,
          //   display: false,
          //   hide: true,
          // },
          {
            label: "角色",
            prop: "roleName",
            display: false,
          },
          {
            label: "手机",
            prop: "phone",
            search: true,
            display: false,
          },
          {
            label: "邮箱",
            prop: "email",
            search: true,
            display: false,
          },
          // {
          //   label: "所属团队",
          //   prop: "teamIds",
          //   type: "tree",
          //   multiple: true,
          //   hide: true,
          //   checkStrictly: true,
          //   dicUrl: "/api/blade-system/dept/tree-new",
          //   props: {
          //     label: "title",
          //     value: "value"
          //   },
          //   search: true,
          //   display: false,
          //   showColumn: false,
          //   rules: [
          //     {
          //       required: true,
          //       message: "请选择所属团队",
          //       trigger: "click",
          //     },
          //   ],
          // },

          {
            label: "所属团队",
            prop: "deptName",
            search: false,
            display: false,
          },
          {
            label: "禁飞区控制",
            prop: "state",
            search: false,
            display: false,
          },
          {
            label: "账号状态",
            prop: "statusZH",
            display: false,
          },
          {
            label: "最后登录",
            prop: "lastLoginTime",
            display: false,
          }
        ],
        group: [
          {
            label: "基础信息",
            prop: "baseInfo",
            icon: "el-icon-user-solid",
            column: [
              // {
              //   label: "所属租户",
              //   prop: "tenantId",
              //   type: "tree",
              //   dicUrl: "/api/blade-system/tenant/select",
              //   props: {
              //     label: "tenantName",
              //     value: "tenantId",
              //   },
              //   // hide: true,
              //   addDisplay: website.tenantMode,
              //  //addDisplay: false,
              //   editDisplay: website.tenantMode,
              //   viewDisplay: website.tenantMode,
              //   rules: [
              //     {
              //       required: true,
              //       message: "请输入所属租户",
              //       trigger: "click",
              //     },
              //   ],
              //   defaultValue: website.tenantId,
              //   span: 24,
              // },
              {
                label: "登录账号",
                prop: "account",
                rules: [
                  {
                    required: true,
                    message: "请输入登录账号",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    validator: maxLength200,
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "用户平台",
                type: "select",
                dicUrl: "/api/blade-system/dict/dictionary?code=user_type",
                props: {
                  label: "dictValue",
                  value: "dictKey",
                },
                dataType: "number",
                slot: true,
                prop: "userType",
                rules: [
                  {
                    required: true,
                    message: "请选择用户平台",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "密码",
                prop: "password",
                type: "password",
                hide: true,
                editDisplay: false,
                viewDisplay: false,
                rules: [
                  { required: true, validator: validatePass, trigger: "blur" },
                ],
              },
              {
                label: "确认密码",
                prop: "password2",
                type: "password",
                hide: true,
                editDisplay: false,
                viewDisplay: false,
                rules: [
                  { required: true, validator: validatePass2, trigger: "blur" },
                ],
              },
            ],
          },
          {
            label: "详细信息",
            prop: "detailInfo",
            icon: "el-icon-s-order",
            column: [
              {
                label: "用户姓名",
                prop: "realName",
                rules: [
                  {
                    required: true,
                    message: "请输入用户姓名",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    validator: maxLength200,
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "手机号码",
                prop: "phone",
                overHidden: true,
                rules: [
                  {
                    validator: validatePhone,
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "电子邮箱",
                prop: "email",
                hide: true,
                overHidden: true,
                rules: [
                  {
                    validator: validateEmails,
                    trigger: "blur",
                  },
                  {
                    required: true,
                    validator: maxLength200,
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "用户性别",
                prop: "sex",
                type: "select",
                dicData: [
                  {
                    label: "男",
                    value: 1,
                  },
                  {
                    label: "女",
                    value: 2,
                  },
                  {
                    label: "未知",
                    value: 3,
                  },
                ],
                hide: true,
              },
              {
                label: "用户生日",
                type: "date",
                prop: "birthday",
                format: "yyyy-MM-dd",
                valueFormat: "yyyy-MM-dd",
                hide: true,
              },
              {
                label: "学历",
                type: "select",
                dicUrl: "/api/blade-system/dict/dictionary?code=education",
                props: {
                  label: "dictValue",
                  value: "dictKey",
                },
                slot: true,
                prop: "education",
              },
              {
                label: "账号状态",
                prop: "statusName",
                hide: true,
                display: false,
              },
              {
                label: "备注",
                prop: "remarks",
                type: "textarea",
                hide: true,
                rules: [
                  {
                    required: false,
                    validator: maxLength300,
                    trigger: "blur",
                  },
                ],
                // display: false
              },
            ],
          },
          {
            label: "职责信息",
            prop: "dutyInfo",
            icon: "el-icon-s-custom",
            column: [
              {
                label: "工号",
                prop: "workNum",
                overHidden: true,
              },
              {
                label: "所属团队",
                prop: "deptId",
                type: "tree",
                dicUrl: "/api/blade-system/dept/getSelectDeptByTenantID",
                props: {
                    label: "deptName",
                    value: "id"
                },
                rules: [
                  {
                    required: true,
                    message: "请选择所属团队",
                    trigger: "click",
                  },
                ],
              },
              {
                label: "所属角色",
                prop: "roleId",
                multiple: true,
                type: "tree",
                dicUrl: "/api/blade-system/role/getSelectAppRoleListByTenant?app=aos-schedule",
                props: {
                    label: "roleName",
                    value: "id"
                },
                checkStrictly: true,
                rules: [
                  {
                    required: true,
                    message: "请选择所属角色",
                    trigger: "click",
                  },
                ],
              },
            ],
          },
        ],
      },
      data: [],
      platformQuery: {},
      platformSelectionList: [],
      platformData: [],
      platformForm: {},
      platformOption: {
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        selection: true,
        viewBtn: true,
        dialogClickModal: false,
        menuWidth: 120,
        editBtnText: "配置",
        column: [
          {
            label: "登录账号",
            prop: "account",
            search: true,
            display: false,
          },
          {
            label: "所属租户",
            prop: "tenantName",
            slot: true,
            display: false,
          },
          {
            label: "用户姓名",
            prop: "realName",
            search: true,
            display: false,
          },
          {
            label: "用户平台",
            prop: "userTypeName",
            slot: true,
            display: false,
          },
          {
            label: "用户平台",
            type: "select",
            dicUrl: "/api/blade-system/dict/dictionary?code=user_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            search: true,
            hide: true,
            display: false,
            prop: "userType",
            rules: [
              {
                required: true,
                message: "请选择用户平台",
                trigger: "blur",
              },
            ],
          },
          {
            label: "用户拓展",
            prop: "userExt",
            type: "textarea",
            minRows: 8,
            span: 24,
            overHidden: true,
            row: true,
            hide: true,
          },
        ],
      },
      excelForm: {},
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "模板上传",
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: "模板上传中，请稍等",
            span: 24,
            propsHttp: {
              res: "data",
            },
            tip: "请上传 .xls,.xlsx 标准格式文件",
            action: "/api/blade-user/import-user",
          },
          {
            label: "数据覆盖",
            prop: "isCovered",
            type: "switch",
            align: "center",
            width: 80,
            dicData: [
              {
                label: "否",
                value: 0,
              },
              {
                label: "是",
                value: 1,
              },
            ],
            value: 0,
            slot: true,
            rules: [
              {
                required: true,
                message: "请选择是否覆盖",
                trigger: "blur",
              },
            ],
          },
          {
            label: "模板下载",
            prop: "excelTemplate",
            formslot: true,
            span: 24,
          },
        ],
      },
      recordData: {},
    };
  },
  watch: {
    "form.tenantId"() {
      if (this.form.tenantId !== "" && this.initFlag) {
        this.initData(this.form.tenantId);
      }
    },
    "excelForm.isCovered"() {
      if (this.excelForm.isCovered !== "") {
        const column = this.findObject(this.excelOption.column, "excelFile");
        column.action = `/api/blade-user/import-user?isCovered=${this.excelForm.isCovered}`;
      }
    },
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.user_add, false),
        viewBtn: this.vaildData(this.permission.user_view, false),
        delBtn: this.vaildData(this.permission.user_delete, false),
        editBtn: this.vaildData(this.permission.user_edit, false),
        flyBtn: this.vaildData(this.permission.no_fly, false),
      };
    },
    platformPermissionList() {
      return {
        addBtn: false,
        viewBtn: false,
        delBtn: false,
        editBtn: this.vaildData(this.permission.user_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  mounted() {
    // 非租户模式默认加载管理组数据
    // if (!website.tenantMode) {
    //   this.initData(website.tenantId);
    // }
  },
  created(){
    this.initData(website.tenantId);
  },
  
  methods: {
    // 修改禁飞区权限
    changeStatus(row) {
      let state
      if (row.noFly === 1) {
        state = 0
      } else if (row.noFly === 0) {
        state = 1
      } else {
        return 
      }
      if (!this.permission.no_fly) {
        return
      }
      const data = {
        state,
        userId: row.id
      }
      const message = state === 0 ? "禁用成功" : "启用成功"
      changeFlyState(data).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message
          })
        },
        (error) => {
          loading();
          window.console.log(error);
        }
      );
    },
    nodeClick(data) {
      this.treeDeptId = data.id;
      this.getUserTreeOption();
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    // 新增获取角色
    getUserOpAdd(val) {
      if (!val.value) {
        return;
      }
      getRoleTree().then((res) => {
        const column = this.findObject(this.option.group[2].column, "roleId");
        column.dicData = res.data.data;
        column.addDisplay = true;
      });
    },
    // 获取角色选项
    getUserTreeOption() {
      getRoleTree().then((res) => {
        const column = this.findObject(this.option.column, "roleId");
        column.dicData = res.data.data;
      });
    },
    // 查看日志
    handleView(row) {
      this.showRecord = true;
      this.recordData = {
        tenantId: row.tenantId,
        userId: row.id,
      };
    },
    initData(tenantId) {
      this.getAddOptions(tenantId);
    },
    submitRole() {
      const roleList = this.$refs.treeRole.getCheckedKeys().join(",");
      grant(this.ids, roleList).then(() => {
        this.roleBox = false;
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.onLoad(this.page);
      });
    },
    rowSave(row, done, loading) {
      let params = JSON.parse(JSON.stringify(row));
      if (params.roleId.length === 1) {
        params.roleId = params.roleId[0];
      } else {
        params.roleId = params.roleId.join(",");
      }

      add(params).then(
        () => {
          this.initFlag = false;
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          window.console.log(error);
          loading();
        }
      );
    },
    rowUpdate(row, index, done, loading) {
      let params = JSON.parse(JSON.stringify(row));
      if (params.roleId.length === 1) {
        params.roleId = params.roleId[0];
      } else {
        params.roleId = params.roleId.join(",");
      }
      update(params).then(
        () => {
          this.initFlag = false;
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          window.console.log(error);
          loading();
        }
      );
    },
    rowDel(row) {
      this.$confirm("确定删除选择用户?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    searchReset() {
      this.query = {};
      this.treeDeptId = "";
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },
    handleReset() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择账号密码重置为123456?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return resetPassword(this.ids);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },
    handleGrant() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.roleTreeObj = [];
      if (this.selectionList.length === 1) {
        this.roleTreeObj = this.selectionList[0].roleId.split(",");
      }
      getRoleTree().then((res) => {
        this.roleGrantList = res.data.data;
        this.roleBox = true;
      });
    },
    handlePlatform() {
      this.platformBox = true;
    },
    handleImport() {
      this.excelBox = true;
    },
    uploadAfter(res, done, loading, column) {
      this.excelBox = false;
      this.refreshChange();
      done();
    },

    getAddOptions(value){
      if (value) {
        getDeptByTenantID(value).then((res) => {
          let dicData = [];
          if (res.data.code === 200) {
            const data = res.data.data;
            dicData=this.transformData(data);
            this.deptList=dicData;
          }
        })

        getAppRoleListByTenant(value,"aos-schedule").then((res) => {
          let dicData = [];
          if (res.data.code === 200) {
            const data = res.data.data;
            for (let i = 0; i < data.length; i += 1) {
              const role = data[i];
              dicData=this.transformData1(role.roleList)
            }
            this.roleList=dicData;
          }
        });
      }
    },

    
    //遍历数据
    transformData(data) {
        return data.map(item => {
          const children = item.children ? item.children.map(child => ({
            title: child.deptName,
            value: child.id
          })) : [];
          return {
            title: item.deptName,
            value: item.id,
            children
          };
        });
      },

      transformData1(data) {
        return data.map(item => {
          const children = item.children ? item.children.map(child => ({
            label: child.roleName,
            value: child.id
          })) : [];
          return {
            label: item.roleName,
            value: item.id,
            children
          };
        });
      },

    // 导出功能
    handleExport() {
      const option = {
        title: "用户信息",
        columns: [
          {
            label: "用户ID",
            prop: "id",
          },
          {
            label: "账户名",
            prop: "account",
          },
          {
            label: "姓名",
            prop: "realName",
          },
          {
            label: "性别",
            prop: "sexName",
          },
          {
            label: "角色",
            prop: "roleName",
            display: false,
          },
          {
            label: "手机",
            prop: "phone",
          },
          {
            label: "邮箱",
            prop: "email",
          },
          {
            label: "账号状态",
            prop: "statusZH",
          },
          {
            label: "最后登录",
            prop: "lastLoginTime",
          },
        ],
      };
      const _this = this;
      exportExcel(option, _this);
    },
    // handleExport() {
    //   this.$confirm("是否导出用户数据?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning"
    //   }).then(() => {
    //     window.open(`/api/blade-user/export-user?${this.website.tokenHeader}=${getToken()}&account=${this.search.account}&realName=${this.search.realName}`);
    //   });
    // },
    handleTemplate() {
      console.log(
        `/api/blade-user/export-template?${
          this.website.tokenHeader
        }=${getToken()}`
      ); 
      window.open(
        `/api/blade-user/export-template?${
          this.website.tokenHeader
        }=${getToken()}`
      );
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getUser(this.form.id).then((res) => {
          this.form = res.data.data;
          if (this.form.hasOwnProperty("roleId")) {
            this.form.roleId = this.form.roleId.split(",");
          }
        });
  
      
      }
      this.initFlag = true;
      done();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      params.app="aos-schedule";
      getuserList(
        page.currentPage,
        page.pageSize,
       
        Object.assign(params, this.query),
        this.treeDeptId
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.data.map((item, index) => {
          item.statusZH = item.state === 1 ? "正常" : "停用";
          this.$set(this.switchArr, index, item.noFly)
        });
        this.loading = false;
        this.selectionClear();
      });
    },
    handleFrezee(row) {
      let state = row.state === 1 ? 0 : 1;
      let msg = row.state === 1 ? "用户停用成功！" : "用户启用成功！";
      let userId = row.id;
      const data = {
        state,
        userId,
      };
      userFreeze(data).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(msg);
          this.refreshChange();
        }
      });
    },
    platformRowUpdate(row, index, done, loading) {
      updatePlatform(row.id, row.userType, row.userExt).then(
        () => {
          this.platformOnLoad(this.platformPage);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          window.console.log(error);
          loading();
        }
      );
    },
    platformBeforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getUserPlatform(this.platformForm.id).then((res) => {
          this.platformForm = res.data.data;
        });
      }
      done();
    },
    platformSearchReset() {
      this.platformQuery = {};
      this.platformOnLoad(this.platformPage);
    },
    platformSearchChange(params, done) {
      this.platformQuery = params;
      this.platformPage.currentPage = 1;
      this.platformOnLoad(this.platformPage, params);
      done();
    },
    platformSelectionChange(list) {
      this.platformSelectionList = list;
    },
    platformSelectionClear() {
      this.platformSelectionList = [];
      this.$refs.platformCrud.toggleSelection();
    },
    platformCurrentChange(currentPage) {
      this.platformPage.currentPage = currentPage;
    },
    platformSizeChange(pageSize) {
      this.platformPage.pageSize = pageSize;
    },
    platformRefreshChange() {
      this.platformOnLoad(this.platformPage, this.platformQuery);
    },
    platformOnLoad(page, params = {}) {
      this.platformLoading = true;
      getuserList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query),
        this.treeDeptId
      ).then((res) => {
        const data = res.data.data;
        this.platformPage.total = data.total;
        this.platformData = data.records;
        this.platformLoading = false;
        this.selectionClear();
      });
    },
  },
};
</script>

<style>
.box {
  height: calc(100vh - 116px);
}

.el-scrollbar {
  height: 100%;
}

.box .el-scrollbar__wrap {
  overflow: scroll;
}

.tree-container {
  min-height: calc(100vh - 116px);
}
</style>
