var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            search: _vm.search,
            "table-loading": _vm.loading,
            data: _vm.data,
            permission: _vm.permissionList,
            "before-open": _vm.beforeOpen,
            page: _vm.page,
          },
          on: {
            "update:search": function ($event) {
              _vm.search = $event
            },
            "row-del": _vm.rowDel,
            "row-update": _vm.rowUpdate,
            "row-save": _vm.rowSave,
            "update:page": function ($event) {
              _vm.page = $event
            },
            "search-change": _vm.searchChange,
            "search-reset": _vm.searchReset,
            "selection-change": _vm.selectionChange,
            "current-change": _vm.currentChange,
            "size-change": _vm.sizeChange,
            "refresh-change": _vm.refreshChange,
            "on-load": _vm.onLoad,
          },
          scopedSlots: _vm._u([
            {
              key: "tenantName",
              fn: function (ref) {
                var row = ref.row
                return [_c("el-tag", [_vm._v(_vm._s(row.tenantName))])]
              },
            },
            {
              key: "roleName",
              fn: function (ref) {
                var row = ref.row
                return [_c("el-tag", [_vm._v(_vm._s(row.roleName))])]
              },
            },
            {
              key: "deptName",
              fn: function (ref) {
                var row = ref.row
                return [_c("el-tag", [_vm._v(_vm._s(row.deptName))])]
              },
            },
            {
              key: "userTypeName",
              fn: function (ref) {
                var row = ref.row
                return [_c("el-tag", [_vm._v(_vm._s(row.userTypeName))])]
              },
            },
            {
              key: "lastLoginTime",
              fn: function (ref) {
                var row = ref.row
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        row.lastLoginTime ? row.lastLoginTime : "暂未登录"
                      ) +
                      "\n    "
                  ),
                ]
              },
            },
            {
              key: "state",
              fn: function (ref) {
                var row = ref.row
                var index = ref.index
                return [
                  _c("avue-switch", {
                    attrs: { disabled: !_vm.permission.no_fly, dic: _vm.dic },
                    on: {
                      click: function ($event) {
                        return _vm.changeStatus(row)
                      },
                    },
                    model: {
                      value: _vm.switchArr[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.switchArr, index, $$v)
                      },
                      expression: "switchArr[index]",
                    },
                  }),
                ]
              },
            },
            {
              key: "menu",
              fn: function (ref) {
                var row = ref.row
                return [
                  _vm.permission.user_status
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            size: "small",
                            icon: "el-icon-circle-check",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleFrezee(row)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(row.state === 1 ? "停用" : "启用") +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _vm.userInfo.role_name.includes("admin")
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "small",
                        plain: "",
                        icon: "el-icon-upload2",
                      },
                      on: { click: _vm.handleImport },
                    },
                    [_vm._v("导入\n      ")]
                  )
                : _vm._e(),
              _vm.userInfo.role_name.includes("admin")
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "warning",
                        size: "small",
                        plain: "",
                        icon: "el-icon-download",
                      },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户角色配置",
            "append-to-body": "",
            visible: _vm.roleBox,
            width: "345px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.roleBox = $event
            },
          },
        },
        [
          _c("el-tree", {
            ref: "treeRole",
            attrs: {
              data: _vm.roleGrantList,
              "show-checkbox": "",
              "check-strictly": "",
              "default-expand-all": "",
              "node-key": "id",
              "default-checked-keys": _vm.roleTreeObj,
              props: _vm.props,
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.roleBox = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitRole } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户数据导入",
            "append-to-body": "",
            visible: _vm.excelBox,
            width: "555px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.excelBox = $event
            },
          },
        },
        [
          _c(
            "avue-form",
            {
              attrs: {
                option: _vm.excelOption,
                "upload-after": _vm.uploadAfter,
              },
              model: {
                value: _vm.excelForm,
                callback: function ($$v) {
                  _vm.excelForm = $$v
                },
                expression: "excelForm",
              },
            },
            [
              _c(
                "template",
                { slot: "excelTemplate" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleTemplate },
                    },
                    [
                      _vm._v("\n          点击下载"),
                      _c("i", {
                        staticClass: "el-icon-download el-icon--right",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户平台配置",
            "append-to-body": "",
            visible: _vm.platformBox,
          },
          on: {
            "update:visible": function ($event) {
              _vm.platformBox = $event
            },
          },
        },
        [
          _c("avue-crud", {
            ref: "platformCrud",
            attrs: {
              option: _vm.platformOption,
              "table-loading": _vm.platformLoading,
              data: _vm.platformData,
              "before-open": _vm.platformBeforeOpen,
              page: _vm.platformPage,
              permission: _vm.platformPermissionList,
            },
            on: {
              "update:page": function ($event) {
                _vm.platformPage = $event
              },
              "row-update": _vm.platformRowUpdate,
              "search-change": _vm.platformSearchChange,
              "search-reset": _vm.platformSearchReset,
              "selection-change": _vm.platformSelectionChange,
              "current-change": _vm.platformCurrentChange,
              "size-change": _vm.platformSizeChange,
              "refresh-change": _vm.platformRefreshChange,
              "on-load": _vm.platformOnLoad,
            },
            scopedSlots: _vm._u([
              {
                key: "tenantName",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("el-tag", [_vm._v(_vm._s(row.tenantName))])]
                },
              },
              {
                key: "userTypeName",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("el-tag", [_vm._v(_vm._s(row.userTypeName))])]
                },
              },
            ]),
            model: {
              value: _vm.platformForm,
              callback: function ($$v) {
                _vm.platformForm = $$v
              },
              expression: "platformForm",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }