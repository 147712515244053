/**
 * 根据逗号分隔
 * @param option
 * @param _this
 *
 * eg: option: {
 *    title: ''
 *    columns: []
 * }
 */
export function exportExcel(option, _this) {
  if (!_this.$refs.crud.tableSelect.length) {
    _this.$message.warning("请选择要导出的内容！");
    return;
  }
  let _export = null;
  const { column, columns } = option;
  if (column) {
    const t = column.filter(i => {
      return !i._noExport;
    });
    _export = t.map(i => {
      if (i.dicData || i.dicUrl) {
        return { ...i, prop: `$${i.prop}` };
      }
      return i;
    });
  }
  _this.$Export.excel({
    title: option.fileName || option.title || "",
    columns: _export || columns || [],
    data: _this.$refs.crud.tableSelect || []
  });
}
